import { useStaticQuery, graphql } from "gatsby"

const useHeaderData = () => {
  const headerData = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
        id
        name
        menuItems {
          nodes {
            id
            label
            title
            path
            parentId
          }
        }
      }
    }
  `)

  return headerData
}

export default useHeaderData

import React from 'react'
import Seo from '../Seo'
import '../../styles/styles.scss'
import Header from '../Header'
import Footer from '../Footer'
import { globalWrapper, globalWrapperHome, showOnFocus } from './Layout.module.scss'
import clsx from "clsx";
import useSeoData  from '../../hooks/use-seo-data'
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo'
import cssVars from 'css-vars-ponyfill';

function isBrowser() {
  return typeof window !== 'undefined';
}

const Layout = ({ isFrontPage, children }) => {
	if (isBrowser()) {
		const cssPolifill = () => cssVars({
			rootElement: document,
			onlyLegacy: false,
			preserveVars: true,
			variables: {
			 '--bs-gutter-x': '1.5rem',
			 '--bs-gutter-y'  : '0'
			},
			onComplete: () => console.log('CSS Variables updated')
		});

		cssPolifill();
  }
	
	const seoData = useSeoData()
	return (
		<SEOContext.Provider value={{ global: seoData }}>
			<div className={clsx(globalWrapper, isFrontPage ? globalWrapperHome : '')}>
				<Seo title="Procopio" />

				{/* Bypass block - shows on focus */}
				<a className={showOnFocus} href="#main-content">
					Skip to main content
				</a>

				<Header isFrontPage={isFrontPage} />
				<main id="main-content">
					{children}
				</main>
				<Footer />
			</div>
		</SEOContext.Provider>
	)
}

export default Layout

import React, { useState } from 'react'
import clsx from "clsx";
import useHeaderData from '../../hooks/header-menu'
import { useScrollPosition } from '../../hooks/use-scroll-position'
import { header, headerHome, styledNav, logo } from './Header.module.scss'
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Burger from "../Burgermenu/Burger";
import HeaderNavigation from "../HeaderNavigation";

const Header = ({ isFrontPage }) => {
  const headerData = useHeaderData()
  const [scroll, setScroll] = useState(0);

  useScrollPosition(function setScrollPosition({ currentPosition }) {
    setScroll(currentPosition.y);
  });

  const pin = isFrontPage && scroll > -150

  return (
    <header className={clsx(header, pin ? headerHome : '')}>
      <div className={styledNav}>
        {/* header logo */}
        <Link to="/">
          {pin ? (
            <StaticImage className={logo} alt="Procopio Logo" src="../../images/logo-white@2x.png" width={122} height={28} layout="fixed" placeholder="none" />
          ) : (
            <StaticImage className={logo} alt="Procopio Logo" src="../../images/logo@2x.png" width={122} height={28} layout="fixed" placeholder="none" />
          )}
        </Link>
        
        {/* mobile menu + popup */}
        <div className="d-md-none">
          <Burger headerNav={headerData.wpMenu?.menuItems?.nodes} isFrontPage={isFrontPage && scroll > -150} />
        </div>
        
        {/* tablet and desktop main navigation + search */}
        <div className={`d-none d-md-flex`}>
            <HeaderNavigation headerNav={headerData.wpMenu?.menuItems?.nodes} isFrontPage={isFrontPage && scroll > -150} />
        </div>
      </div>
    </header>
  )
}

export default Header
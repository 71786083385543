// Sometimes you won’t know ahead of time whether a link will be internal or not,
// such as when the data is coming from a CMS. In these cases you may find it
// useful to make a component which inspects the link and renders either with
// Gatsby’s <Link> or with a regular <a> tag accordingly.
// THIS IS THAT COMPONENT!

import React, {forwardRef} from 'react'
import { Link as GatsbyLink } from 'gatsby'

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = forwardRef((props, ref) => {
	const { label, children, to, activeClassName, partiallyActive, target } = props
	const file = /\.[0-9a-z]+$/i.test(to)
	// Tailor the following test to your environment.
	// This example assumes that any internal link (intended for Gatsby)
	// will start with exactly one slash, and that anything else is external.
	const internal = target !== '_blank' && /^\/(?!\/)/.test(to)
	const isLink = typeof to !== 'undefined'
	const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '')

	// Use Gatsby Link for internal links, and <a> for others
	if (internal) {
		if (file) {
			return (
				<a href={to} {...props} ref={ref}>
					{children}
				</a>
			)
		}

		// Use <Link>, called <GatsbyLink> here, for internal links!!!
		// Gatsby uses the <a> element only for links to pages on other domains
		// or pages on the same domain not handled by the current Gatsby site.
		return (
			<GatsbyLink
				to={to}
				activeClassName={activeClassName}
				partiallyActive={partiallyActive}
				ref={ref}
				{...props}
			>
				{children}
			</GatsbyLink>
		)
	}

	return (
		<a href={to} target="_blank" rel="noopener noreferrer" aria-label={label} ref={ref}>
			{children} <span className="sr-only">(opens in a new tab)</span>
		</a>
	)
});

export default Link

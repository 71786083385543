import React from "react"
import styled from "styled-components"

const StyledLogo = styled.img`
  max-height: ${({ height }) => (height ? `${height}px` : 0)};
  max-width: ${({ width }) => (width ? `${width}px` : 0)};
  padding: ${({ padding }) => (padding ? `${padding}px` : 0)};
`

const Logo = ({ logoSrc, height, width, padding }) => (
  <StyledLogo
    src={logoSrc ? logoSrc : ""}
    height={height}
    width={width}
    padding={padding}
    alt={"Logo"}
  />
)

export default Logo

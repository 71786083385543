import { useStaticQuery, graphql } from "gatsby"

const useFooterData = () => {
  const footerData = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: GATSBY_FOOTER_MENU }) {
        id
        name
        menuItems {
          nodes {
            id
            label
            title
            path
            parentId
          }
        }
      }
    }
  `)
  return footerData
}

export default useFooterData

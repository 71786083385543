import React from 'react'
import Link from '../Link'
import clsx from "clsx";
import { headerNavigation, mainMenu, navLink, navLinkHome, icon, active } from './HeaderNavigation.module.scss'

const HeaderNavigation = ({ headerNav, ...props }) => {
	const { isFrontPage } = props

	return (
		<>
      {/* tablet and desktop main navigation */}
			<nav className={headerNavigation}>
				<ul className={mainMenu}>
					<li>
						<Link
							className={clsx(navLink, isFrontPage ? navLinkHome : '')}
							to="/">
							<span className="screen-reader-text">Home page</span>
							<i className="ss-icon ss-black-tie-solid ss-home" />
						</Link>
					</li>
					{headerNav &&
						headerNav.map(v => (
							<li key={v.label}>
								<Link
									activeClassName={active}
									className={clsx(navLink, isFrontPage ? navLinkHome : '')}
									key={v.label}
									to={v.path}
								>
									{v.label}
								</Link>
							</li>
						))}

					<li>
						<Link
							className={clsx(navLink, isFrontPage ? navLinkHome : '')}
							key="search"
							to="/search/"
							aria-label="Search"
						>
							<i className={`${icon} ss-black-tie-bold ss-search`} />
						</Link>
					</li>
				</ul>
			</nav>
		</>
	)
}

export default HeaderNavigation

import { useStaticQuery, graphql } from 'gatsby'

export const useFooterCta = () => {
	const { wp } = useStaticQuery(
		graphql`
			query FooterCta {
				wp {
					procopioThemeSettings {
						procopioFooterCtaOptions {
							cta {
								ctaText
								ctaLink
								headline
							}
						}
					}
				}
			}
		`
	)
	return wp?.procopioThemeSettings?.procopioFooterCtaOptions?.cta
}

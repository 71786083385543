import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import clsx from "clsx";
import useFooterData from "../../hooks/footer-menu";
import useSiteMetadata from '../../hooks/use-site-meta'
import Link from '../Link'

import {
	rightNav,
	rightNavHome,
	rightNavInner,
	mainMenu,
	openNav,
	navLink,
	navLinkHome,
	active,
	navLogo,
	social,
	footerLinks,
	closeNav,
} from './MenuPopup.module.scss'

const MenuPopup = ({ modal, ref, open, headerNav, closeHandler, ...props }) => {
	const { isFrontPage } = props
	const metaData = useSiteMetadata()
	const footerData = useFooterData()
	const socialLinks = metaData?.site?.siteMetadata?.social
	const footerNav = footerData?.wpMenu?.menuItems?.nodes

	return (
		<div role="dialog" aria-labelledby="mobile-menu-dialog" aria-modal="true" className={clsx(rightNav, open ? openNav : '', isFrontPage ? rightNavHome : '')} >

			<div className={rightNavInner} ref={modal}>
				<h2 className="sr-only" id="mobile-menu-dialog">Main Menu</h2>

				<div className={`text-center d-md-none ${navLogo}`}>
					<StaticImage
						src={`../../images/logo-procopio-white.svg`}
						alt={`Procopio`}
						width={120}
					/>
				</div>

				{/* primary links */}
				<nav>
					<ul className={mainMenu}>
						{headerNav &&
							headerNav.map(v => (
								<li key={v.label}>
									<Link
										activeClassName={active}
										className={clsx(navLink, isFrontPage ? navLinkHome : '')}
										key={v.label}
										to={v.path}
										tabIndex={open ? 0 : -1}
									>
										{v.label}
									</Link>
								</li>
							))}
					</ul>
				</nav>

				<div className={social}>
					<ul className="list-inline" aria-label={`Links to Social Media`}>
						{socialLinks &&
							socialLinks.map((v, index) => (
								<li key={index} className={`list-inline-item`}>
									<Link
										key={v.label}
										to={v.path}
										target="_blank"
										label={`Connect with us on ${v.label} (opens in a new tab).`}
										tabIndex={open ? 0 : -1}
									>
										<i className={`fa-brands ${v.icon}`} />
									</Link>
								</li>
							))}
					</ul>
				</div>

				<div className={footerLinks}>
					<ul className={`list-inline`}>
						{footerNav.map((item, index) => (
							<li className={`list-inline-item`} key={index}>
								<Link to={item.path} tabIndex={open ? 0 : -1}>
									{item.label}
								</Link>
							</li>
						))}
					</ul>
				</div>

				{/* close mobile menu */}
				<button onClick={() => closeHandler(false)} type={`button`} className={`${closeNav} d-md-none`} tabIndex={open ? 0 : -1}>
					<span className="screen-reader-text">Close Menu</span>
				</button>
			</div>
		</div>
	)
}

export default MenuPopup

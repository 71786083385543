import React from 'react'
import Button from '../Button'
import { wrapper, heading } from './CallToAction.module.scss'

const CallToAction = ({ href, buttonText, headingText }) => {
	return (
		<div className={wrapper}>
			{headingText ? <h2 className={heading}>{headingText}</h2> : null}

			{/* for button theme and size options, refer to the button component */}
			<Button buttonText={buttonText} to={href} size={'large'} />
		</div>
	)
}

export default CallToAction

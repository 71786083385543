import React, { useState, useEffect, useRef } from "react"
import clsx from "clsx";
import MenuPopup from "./MenuPopup"
import { burger, burgerHome } from './Burger.module.scss'

const Burger = ({ headerNav, ...props}) => {
  const { isFrontPage} = props
  const [open, _setOpen] = useState(false)
  const openRef = useRef(open)
  const modalRef = useRef();

  const setOpen = isOpen => {
    openRef.current = isOpen; // keep updated
    _setOpen(isOpen);
  };
  
  const handleKeyUp = (e) => {
    if (e.key === 'Escape' && openRef.current) {
      setOpen(false);
    }
  }
      
  // Trap focus in modal and handle forward/backwards tabbing
  const handleKeyDown = (e) => {
    if(openRef.current) {
      const focusableModalElements = modalRef.current.querySelectorAll(
        'a[href], button'
      );
      
      const firstElement = focusableModalElements[0];
      
      const lastElement =
        focusableModalElements[focusableModalElements.length - 1];
      
      if (document.activeElement === lastElement) {
        if (e.key === 'Tab') {
          firstElement.focus();
          e.preventDefault();
        }
        
        if (e.shiftKey) {
          focusableModalElements[focusableModalElements.length - 2].focus();
          e.preventDefault();
        }
      }
  
      if (document.activeElement === firstElement) {
        if (e.shiftKey && e.key === 'Tab') {
          lastElement.focus();
          e.preventDefault();
        }
      }
    } 
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <>
      <button className={clsx(burger, isFrontPage ? burgerHome : '')} onClick={() => setOpen(!open)}>
        <span className="screen-reader-text">Open Menu</span>
        <div />
        <div />
        <div />
      </button>
      
      {/* mobile menu popup */}
      <MenuPopup headerNav={headerNav} open={open} modal={modalRef} closeHandler={setOpen} {...props} />
    </>
  )
}

export default Burger

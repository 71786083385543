import { useStaticQuery, graphql } from "gatsby"

const useSiteMetadata = () => {
  const sitemetadata = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            social {
              icon
              label
              path
            }
            url
            title
            subtitle
            copyright
          }
        }
      }
    `
  )
  return sitemetadata
}
export default useSiteMetadata

import React from 'react'
import { Link } from 'gatsby'
import {
	proButton,
	proButtonWhite,
	proButtonBlack,
	proButtonGray,
	proButtonOrange,
	proButtonLightGray,
	proButtonTransparent,
	proButtonTransparentWhite,
	proButtonSmall,
	proButtonLarge,
} from './Button.module.scss'

/**
  Examples:

  <Button buttonText="HTML Button CTA" theme={'white'} />
	<Button buttonText="Internal Gatsby Link" to="/styleguide" theme={'black'} size={'small'} />
	<Button buttonText="External Link" href="www.google.com" /> 
  
  Default button is the medium sized orange button - no Theme or Size parameters needed
  Theme Options: ['white' 'black' 'gray' 'light gray' 'transparent']
  Size Options: '[small' 'large']
*/

/** ACK- Props are Read-Only - A React component must never modify its own props, therefore we assign our theme and size css modules names to variable proSize and proTheme and use in the interpolation of classes. https://reactjs.org/docs/components-and-props.html
 */

const Button = ({ theme, size, buttonText, to, href, type, file, ...props }) => {
	let proTheme = null
	let proSize = null
	switch (theme) {
		case 'white':
			proTheme = proButtonWhite
			break
		case 'black':
			proTheme = proButtonBlack
			break
		case 'gray':
			proTheme = proButtonGray
			break
		case 'light gray':
			proTheme = proButtonLightGray
			break
		case 'transparent':
			proTheme = proButtonTransparent
			break
		case 'transparent-white':
			proTheme = proButtonTransparentWhite
			break
		case 'orange':
			proTheme = proButtonOrange
			break;
		default:
			proTheme = null
	}

	switch (size) {
		case 'small':
			proSize = proButtonSmall
			break
		case 'large':
			proSize = proButtonLarge
			break
	}

	let classes = `${proButton} ${proTheme || ''} ${proSize || ''}`
	classes = `${classes} ${props.className || ''}`
	const buttonType = `${type == 'submit' ? 'submit' : 'button'}`

	const emailRegEx = /^\S+@\S+\.\S+$/

	// If the `to` prop exists, return a gatsby link.
	// Note Gatsby link automatically adds aria-current to current page link.
	if (to) {
		return (
			<Link className={classes} to={to} onClick={props?.onClick}>
				{buttonText}
			</Link>
		)
	}

	// if the `to` prop does not exist but `href` does, return a <a> element.
	// Opens a new tab for external links. Includes screen reader warning.
	// Add mailto to href attribute for email links (do not open in new tab)
	if (href) {
		const internal = /^\/(?!\/)/.test(href)

		if (file) {
			return (
				<a
					className={classes}
					href={href}
					target="_blank"
					rel="noopener noreferrer"
				>
					{buttonText} <span className="sr-only">(opens in a new tab)</span>
				</a>
			)
		}

		if (internal) {
			return (
				<Link className={classes} to={href}>
					{buttonText}
				</Link>
			)
		} else {
			if (emailRegEx.test(href)) {
				// if link matches email, prefix href attribute with 'mailto'
				return (
					<a className={classes} href={`mailto:${href}`}>
						{buttonText}
					</a>
				)
			} else {
				// external links
				return (
					<a
						className={classes}
						href={href}
						target="_blank"
						rel="noopener noreferrer"
					>
						{buttonText} <span className="sr-only">(opens in a new tab)</span>
					</a>
				)
			}
		}
	}

	// If the `to` or `href` props do not exist, return a <button> element.
	// Buttons should be used for in page interaction and do not send the user to a new URL.
	return (
		<button className={classes} type={buttonType} {...props}>
			{buttonText}
		</button>
	)
}

export default Button

import { useStaticQuery, graphql } from 'gatsby'

export const useMenus = (location = null) => {
	const { allWpMenu } = useStaticQuery(
		graphql`
			query AllMenus {
				allWpMenu {
					edges {
						menu: node {
							slug
							name
							locations
							menuItems {
								nodes {
									url
									label
									target
								}
							}
						}
					}
				}
			}
		`
	)
	return selectMenu(allWpMenu?.edges, location)
}

const selectMenu = (menus, locationSlug) => {
	return menus.filter(({ menu }) => {
		return locationSlug && menu.locations[0] === locationSlug.toUpperCase()
	})
}
